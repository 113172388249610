<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            label="관리부서"
            name="deptCd"
            placeholder='LBLDEPT'
            v-model="searchParam.deptCd">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text 
            label="검색어" 
            name="keyword" 
            v-model="searchParam.keyword">
          </c-text>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="설비 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      :usePaging="false"
      cardClass="topcolor-orange"
      rowKey="equipCd"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
        <c-btn v-if="popupParam.isPreview" label="미리보기" icon="check" @btnClicked="preview" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn v-if="!popupParam.isPreview" label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'equipment-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          isPreview: false, // 미리보기 속성
          isCheck: false, // 설비팝업
          title: '' // 점검/정비
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantNm',
            field: 'plantNm',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'equipCd',
            field: 'equipCd',
            label: '설비코드',
            align: 'center',
            sortable: true,
          },
          {
            name: 'equipNm',
            field: 'equipNm',
            label: '설비명',
            align: 'center',
            sortable: true,
            type: 'link',
          },
          {
            name: 'equipType',
            field: 'equipType',
            label: '설비유형',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptNm',
            field: 'deptNm',
            label: '관리부서',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'processNm',
            field: 'processNm',
            label: 'LBLPROCESS',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
        height: '350px'
      },
      searchParam: {
        plantCd: null,
        deptCd: null,
        keyword: '',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      userNm: '',
      userId: '',
      chkYmd: '',
      editable: true,
      searchUrl: '',
      selectData: null,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          plantNm: '1사업장',
          deptNm: ' 부서명1',
          processNm: '공정1',
          equipType: '반응기',
          equipCd: 'EQ-00001',
          equipNm: '설비명1'
        },
        {
          plantNm: '1사업장',
          deptNm: ' 부서명1',
          processNm: '공정1',
          equipType: '반응기',
          equipCd: 'EQ-00002',
          equipNm: '설비명2'
        },
        {
          plantNm: '1사업장',
          deptNm: ' 부서명1',
          processNm: '공정2',
          equipType: '압력용기',
          equipCd: 'EQ-00003',
          equipNm: '설비명3'
        },
      ]
    },
    select() {
      this.selectData = this.$refs['table'].selected;
      if (!this.selectData || this.selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '설비를 지정하세요.', // 설비를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', { 
          selectData: this.selectData,
          userId: this.userId,
          userNm: this.userNm,
          chkYmd: this.chkYmd,
        });
      }
    },
    linkClick(row) {
      this.popupOptions.title = '설비 상세';
      this.popupOptions.param = row;
      this.popupOptions.target = () => import(`${'./equipmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    rowClick(row) {
      this.selectData = row;
    },
    rowDblclick(row) {
      this.selectData = row;
      this.$emit('closePopup', { 
        selectData: this.selectData,
      });
    },
    preview() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '설비를 지정하세요.', // 설비를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.popupOptions.param = selectData;
        this.popupOptions.target = () => import(`${'./equipmentPreview.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      }
    },
    username(data) {
      this.userNm = data;
    }
  }
};
</script>
